import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
  return (
    <Layout pageTitle="About">
      <div className="container">
        <div className="p-3 pb-md-4 mx-auto text-center">
          <h1 className="display-4 fw-normal">About</h1>
          <p className="fs-5">Saya adalah seorang programmer yang tinggal di Indonesia dan juga pemilik website ini.</p>
          <p>Silakan hubungi saya di zemmyindrapatih@yahoo.com.</p>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
